/*body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}*/

body {
  background-color: #18191A;
  color: #D0D0D0;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.cs-message__footer .cs-message__sent-time {
  color: #DEDEDE !important;
}

.cs-chat-container .cs-message-input {
  border-top: 1px solid #575757 !important;
}

.cs-main-container {
  border-top: 1px solid #575757 !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-bottom: 0px !important;
}